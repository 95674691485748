import '../bootstrap';
import '../../css/beautydose.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../../vendor/tightenco/ziggy/dist/vue.m';
import Popper from "vue3-popper";
import * as Sentry from "@sentry/vue";
import {createI18n} from 'vue-i18n';
import Notifications from 'notiwind';
import FloatingVue from 'floating-vue'

const appName = 'Beauty Dose';

const datetimeFormats = {
    'es': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
        },
        month: {
            month: 'long',
        }
    }
}

const i18n = createI18n({
    datetimeFormats,
    locale: "es",
})

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        Sentry.init({
            app,
            environment: import.meta.env.VITE_SENTRY_ENVIROMENT,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            trackComponents: true,
        });

        return app
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(Notifications)
            .use(i18n)
            .use(Popper)
            .use(FloatingVue)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
